import { init } from '@jill64/sentry-sveltekit-cloudflare/client'

const onError = init(
  'https://319b076dcdc386946033ebd9d20cd853@o4506592022298624.ingest.us.sentry.io/4508697792282624',
  {
    sentryOptions: {
      tracesSampleRate: 1.0,

      // This sets the sample rate to be 10%. You may want this to be 100% while
      // in development and sample at a lower rate in production
      replaysSessionSampleRate: 0.1,

      // If the entire session is not sampled, use the below sample rate to sample
      // sessions when an error occurs.
      replaysOnErrorSampleRate: 1.0,
    },
  });

// If you have a custom error handler, pass it to `handleErrorWithSentry`
export const handleError = onError();
